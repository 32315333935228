import React, { useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../App'; // Assuming UserContext is exported from App.js

const Notifications = () => {
  const { state } = useContext(UserContext); // Assuming state contains user login information

  const handleNotificationClose = useCallback((notification) => {
    // Implement logic to store closed notifications
    // Example: You can store closed notification IDs in localStorage
    // and check against them before showing new notifications
    // to prevent showing the same notification multiple times.
    if (state.userLoggedIn) {
      // Implement logic to save notification ID in localStorage or backend
      // For demonstration, just console.log the notification closed
      console.log('Notification closed:', notification);
    }
  }, [state.userLoggedIn]);

  const showNotifications = useCallback((notifications) => {
    notifications.forEach(notification => {
      toast.info(notification.message, {
        position: "top-center", // Position at top-center
        autoClose: 5000, // Close the toast after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: true, // Show close button
        onClose: () => handleNotificationClose(notification), // Handle close event
        style: {
          zIndex: 3001, // Set zIndex higher than 3000
        }
      });
    });
  }, [handleNotificationClose]);

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications`); // Ensure the endpoint is correct
      console.log(response.data)
      showNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [showNotifications]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]); // Add fetchNotifications to dependency array

  return (
    <>
      <h2>Notifications</h2>
      <ToastContainer position="top-center" /> {/* ToastContainer for react-toastify */}
    </>
  );
};

export default Notifications;
