import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, TextField, Button, MenuItem, Select, } from "@mui/material";
import "./ProjectTracker.css";
import { ToastContainer, toast } from "react-toastify";

const ProjectTracker = ({ onClose }) => {
  const { id } = useParams();
  const userId = id;

  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "",
      projectName: "",
      location: "",
      consultant: "",
      contractor: "",
      projectType: "",
      quotedValue: "",
      quotedDate: "",
      followupDate: "",
      projectStatus: "",
      specifications:"",
      MakeList: "",
      person: "",
      amount: "",
      status: "",
      comment: "",
    },
  ]);

  const [projectDetails, setProjectDetails] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [consultantSuggestions, setConsultantSuggestions] = useState([]);
  const [contractorSuggestions, setContractorSuggestions] = useState([]);
  const [userName, setUserName] = useState("");
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [currentCommentRow, setCurrentCommentRow] = useState(null);
  const [allowedColumns, setAllowedColumns] = useState([]);
  const [comment, setComment] = useState("");
  const suggestionsRef = useRef(null);


  const handleMakeListChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, MakeList: value, specifications: "" } : row))
    );
  };

  
  const handleSpecificationsChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, specifications: value } : row))
    );
  };
  

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}project-names`
        );
        const result = await response.json();
        setProjectDetails(result.projectDetails || []);
      } catch (error) {
        console.error("Error fetching project names:", error);
      }
    };

    const fetchUserName = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}users/${userId}`
        );
        const result = await response.json();
        const fullName = `${result.FirstName || ""} ${result.LastName || ""}`;
        setUserName(fullName);
        setRows([
          {
            id: 1,
            userName: fullName,
            projectName: "",
            location: "",
            consultant: "",
            contractor: "",
            projectType: "",
            quotedValue: "",
            quotedDate: "",
            followupDate: "",
            projectStatus: "",
            MakeList: "",
            person: "",
            amount: "",
            status: "",
            comment: "",
          },
        ]);
        setAllowedColumns(result.AllowedColumns || []);
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };

    fetchProjectDetails();
    fetchUserName();
  }, [userId]);

  const handleInputChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );

    // Suggestion handling for project name
    if (field === "projectName") {
      const filteredSuggestions = projectDetails.filter((project) =>
        project.projectName?.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }

    // Suggestion handling for consultant
    if (field === "consultant") {
      const filteredConsultantSuggestions = projectDetails.filter((project) =>
        project.consultant?.toLowerCase().startsWith(value.toLowerCase())
      );
      setConsultantSuggestions(filteredConsultantSuggestions);
    }

    // Suggestion handling for contractor
    if (field === "contractor") {
      const filteredContractorSuggestions = projectDetails.filter((project) =>
        project.contractor?.toLowerCase().startsWith(value.toLowerCase())
      );
      setContractorSuggestions(filteredContractorSuggestions);
    }

    // Reset fields if project name is cleared
    if (field === "projectName" && value === "") {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id
            ? { ...row, location: "", consultant: "", contractor: "" }
            : row
        )
      );
    }
  };

  const handleCommentClick = (rowId) => {
    setCurrentCommentRow(rowId);
    const existingComment = rows.find((row) => row.id === rowId)?.comment || "";
    setComment(existingComment);
    setIsCommentSectionOpen(true);
  };

  const handleSaveComment = () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === currentCommentRow ? { ...row, comment } : row
      )
    );
    setIsCommentSectionOpen(false);
  };

  const handleCancelComment = () => {
    setIsCommentSectionOpen(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setRows((prevRows) =>
      prevRows.map((rows) =>
        rows.id === 1
          ? {
            ...rows,
            projectName: suggestion.projectName,
            location: suggestion.location,
            consultant: suggestion.consultant,
            contractor: '',
          }
          : rows
      )
    );
    setSuggestions([]);
    setConsultantSuggestions([]);
    setContractorSuggestions([]);

  };

  const handleConsultantSuggestionClick = (suggestion) => {
    setRows((prevRows) =>
      prevRows.map((rows) =>
        rows.id === 1
          ? { ...rows, consultant: suggestion.consultant }
          : rows
      )
    );
    setConsultantSuggestions([]);
  };

  const handleContractorSuggestionClick = (suggestion) => {
    setRows((prevRows) =>
      prevRows.map((rows) =>
        rows.id === 1
          ? { ...rows, contractor: suggestion.contractor }
          : rows
      )
    );
   
    setContractorSuggestions([]);
  };


  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setSuggestions([]);
      setConsultantSuggestions([]);
      setContractorSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async () => {
    // Ensure that the default date is set if it's not provided
    const updatedRows = rows.map(row => ({
      ...row,
      quotedDate: row.quotedDate || new Date().toISOString().split("T")[0] // Set to today's date if empty
    }));
  
    console.log("Submitting rows:", updatedRows);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}add-project/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rows: updatedRows }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to add projects");
      }
  
      const result = await response.json();
      console.log(result.message);
  
      setRows([
        {
          id: 1,
          userName: userName,
          projectName: "",
          location: "",
          consultant: "",
          contractor: "",
          projectType: "",
          quotedValue: "",
          quotedDate: new Date().toISOString().split("T")[0], // Reset to today's date
          followupDate: "",
          projectStatus: "",
          MakeList: "",
          specifications: "",
          person: "",
          amount: "",
          status: "",
          comment: "",
          createdAt: Date.now(),
        },
      ]);
  
      // Show success toast
      toast.success("Projects added successfully!");
    } catch (error) {
      console.error("Error submitting projects:", error);
    }
  };
  


  return (
    <div className="projecttracker-wrap">
      <Paper className="add-project">
        {rows.map((row) => (
          <Grid key={row.id} container spacing={2}>
            {allowedColumns.includes("UserName") && (
              <>
                <Grid item xs={6}>
                  <TextField value={rows[0].userName} onChange={(e) => handleInputChange(1, "userName", e.target.value)} disabled fullWidth />
                </Grid>
              </>
            )}
            {allowedColumns.includes("ProjectName") && (
              <Grid item xs={6}>
                <TextField label="Project Name" value={row.projectName} onChange={(e) => handleInputChange(row.id, "projectName", e.target.value)} fullWidth />
                {suggestions.length > 0 && (
                  <div ref={suggestionsRef} className="suggestions-dropdown">
                    {suggestions.map((suggestion, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.projectName}
                      </MenuItem>
                    ))}
                  </div>
                )}
              </Grid>
            )}
            {allowedColumns.includes("Location") && (
              <Grid item xs={6}>
                <TextField label="Location" value={row.location} onChange={(e) => handleInputChange(row.id, "location", e.target.value)} fullWidth />
              </Grid>
            )}
            {allowedColumns.includes("Consultant") && (
              <Grid item xs={6}>
                <TextField
                  label="Consultant"
                  value={row.consultant}
                  onChange={(e) =>
                    handleInputChange(row.id, "consultant", e.target.value)
                  }
                  fullWidth
                />
                {consultantSuggestions.length > 0 && (
                  <div ref={suggestionsRef} className="suggestions-dropdown">
                    {consultantSuggestions.map((suggestion, index) => (
                      <MenuItem
                        key={index}
                        onClick={() =>
                          handleConsultantSuggestionClick(suggestion)
                        }
                      >
                        {suggestion.consultant}
                      </MenuItem>
                    ))}
                  </div>
                )}
              </Grid>
            )}
            {allowedColumns.includes("Contractor") && (
              <Grid item xs={6}>
                <TextField
                  label="Contractor"
                  value={row.contractor}
                  onChange={(e) =>
                    handleInputChange(row.id, "contractor", e.target.value)
                  }
                  fullWidth
                />
                {contractorSuggestions.length > 0 && (
                  <div ref={suggestionsRef} className="suggestions-dropdown">
                    {contractorSuggestions.map((suggestion, index) => (
                      <MenuItem
                        key={index}
                        onClick={() =>
                          handleContractorSuggestionClick(suggestion)
                        }
                      >
                        {suggestion.contractor}
                      </MenuItem>
                    ))}
                  </div>
                )}
              </Grid>
            )}
            {allowedColumns.includes("ProjectType") && (
              <Grid item xs={6}>
                <TextField label="Project Type" value={row.projectType} onChange={(e) => handleInputChange(row.id, "projectType", e.target.value)} fullWidth />
              </Grid>
            )}
            {allowedColumns.includes("QuotedValue") && (
              <Grid item xs={6}>
                <TextField label="Quoted Value" type="number" value={row.quotedValue} onChange={(e) => handleInputChange(row.id, "quotedValue", e.target.value)} fullWidth />
              </Grid>
            )}
            {allowedColumns.includes("QuotedDate") && (
  <Grid item xs={6}>
    <TextField
      label="Quoted Date" // This acts as a placeholder for the date input
      type="date"
      value={row.quotedDate || new Date().toLocaleDateString("en-CA")} // Use "en-CA" format for yyyy-MM-dd
      onChange={(e) => handleInputChange(row.id, "quotedDate", e.target.value)}
      fullWidth
      InputProps={{
        inputProps: {
          max: new Date().toISOString().split("T")[0], // Disable future dates
        },
      }}
      InputLabelProps={{
        shrink: true, // Keeps the label when the date picker is open
      }}
    />
  </Grid>
)}

            {allowedColumns.includes("FollowupDate") && (
              <Grid item xs={6}>
                <TextField
                  label="Follow Up Date" // This acts as a placeholder for the date input
                  type="date"
                  value={row.followupDate}
                  onChange={(e) => handleInputChange(row.id, "followupDate", e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true, // Keeps the label when the date picker is open
                  }}
                />
              </Grid>
            )}
            {allowedColumns.includes("ProjectStatus") && (
              <Grid item xs={6}>
                <Select value={row.projectStatus || "Select Status"} onChange={(e) => handleInputChange(row.id, "projectStatus", e.target.value)} fullWidth >
                  <MenuItem value="Select Status" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="Bidding">Bidding</MenuItem>
                  <MenuItem value="Contractor-Won">Contractor Won</MenuItem>
                  <MenuItem value="Contractor Lost">Contractor Lost</MenuItem>
                  <MenuItem value="Omicron Los">Omicron Lost</MenuItem>
                  <MenuItem value="Omicron Won">Omicron Won</MenuItem>
                  <MenuItem value="Part PO Reciv">Part PO Recive</MenuItem>
                  <MenuItem value="Complete PO Recive">Complete PO Recive</MenuItem>
                </Select>
              </Grid>
            )}
            {allowedColumns.includes("MakeList") && (
          <Grid item xs={6}>
          <Select
            value={row.MakeList || "Select Make"}
            onChange={(e) => handleMakeListChange(row.id, e.target.value)}
            fullWidth
          >
            <MenuItem value="Select Make" disabled>
              Select Make
            </MenuItem>
            <MenuItem value="Make Approved">Make Approved</MenuItem>
            <MenuItem value="Make Approved With Spec">Make Approved With Spec</MenuItem>
            <MenuItem value="Not Approved">Not Approved</MenuItem>
          </Select>
          
          {row.MakeList && (
            <TextField
              label="Add Specifications"
              value={row.specifications}
              onChange={(e) => handleSpecificationsChange(row.id, e.target.value)}
              placeholder="Enter specifications, separated by commas"
              fullWidth
              multiline
            />
          )}
        </Grid>
        
            )}
            {allowedColumns.includes("Person") && (
              <Grid item xs={6}>
                <TextField label="Person" value={row.person} onChange={(e) => handleInputChange(row.id, "person", e.target.value)} fullWidth />
              </Grid>
            )}
            {allowedColumns.includes("Amount") && (
              <Grid item xs={6}>
                <TextField label='Amount' type="text" value={row.amount} onChange={(e) => handleInputChange(row.id, "amount", e.target.value)} fullWidth />
              </Grid>
            )}
            {allowedColumns.includes("Status") && (
              <Grid item xs={6}>
                <Select value={row.status || 'Select Status'} onChange={(e) => handleInputChange(row.id, "status", e.target.value)} fullWidth>
                  <MenuItem value="Select Status" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="Transferd">Transferd</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                </Select>
              </Grid>
            )}
            {allowedColumns.includes("Comment") && (
              <Grid item xs={12}>
                {row.comment ? ( // Check if a comment exists
                  <div className="comment-preview-box">
                    <p className="comment-preview">{row.comment}</p> {/* Display saved comment as preview */}
                    <Button className="edit-btn" onClick={() => handleCommentClick(row.id)} >
                      Edit
                    </Button>
                  </div>
                ) : (
                  <Button className="comment-btn" onClick={() => handleCommentClick(row.id)} fullWidth >
                    Comment
                  </Button>
                )}
                {isCommentSectionOpen && currentCommentRow === row.id && (
                  <div className="comment-overlay">
                    <div className="comment-box">
                      <textarea className="comment-input" rows={5} value={comment} onChange={(e) => setComment(e.target.value)} />
                      <div className="button-box">
                        <Button className="submit-btn" onClick={handleSaveComment} > Save </Button>
                        <Button className="submit-btn" onClick={handleCancelComment} > Cancel </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            )}
            <Grid item xs={6}>
              <Button className="submit-project-btn" onClick={handleSubmit} fullWidth>  Submit </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="cancle-project-btn" onClick={onClose} fullWidth> Cancel </Button>
            </Grid>
          </Grid>
        ))}
      </Paper>
      <ToastContainer /> {/* Add this line to include the ToastContainer */}
    </div>
  );
};

export default ProjectTracker;
