import React, { useEffect, useState } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Reports.css"; // Ensure your CSS file is linked here
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function Reports({ userId, userName }) {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchStats() {
      if (!userId) {
        setError("No user ID provided.");
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}user-projects/stats/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        const data = await response.json();
        setStats(data.stats);
        setLoading(false);
      } catch (err) {
        setError("No project data found");
        setLoading(false);
      }
    }
    fetchStats();
  }, [userId]);

  // Function to format numbers as rupees
  const formatCurrency = (amount) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    }).format(amount);

  if (loading)
    return (
      <div className="centered-message">
        <p>Loading...</p>
      </div>
    );

  if (error)
    return (
      <div className="centered-message">
        <p>{error}</p>
      </div>
    );

  if (!stats)
    return (
      <div className="centered-message">
        <p>No data available</p>
      </div>
    );

  // Prepare data for charts
  const barData = {
    labels: [
      "Bidding",
      "Contractor Won",
      "Contractor Lost",
      "Omicron Lost",
      "Omicron Won",
      "Part PO Received",
      "Complete PO Received",
    ],
    datasets: [
      {
        label: "Number of Projects",
        data: [
          stats.biddingValue,
          stats.contractorWonValue,
          stats.contractorLostValue,
          stats.omicronLostValue,
          stats.omicronWonValue,
          stats.partPOReciveValue,
          stats.completePOReciveValue,
        ],
        backgroundColor: "rgba(241, 218, 139, 0.979)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const doughnutData = {
    labels: [
      "Bidding",
      "Contractor Won",
      "Contractor Lost",
      "Omicron Lost",
      "Omicron Won",
      "Part PO Received",
      "Complete PO Received",
    ],
    datasets: [
      {
        label: "Projects by Status",
        data: [
          stats.biddingValue,
          stats.contractorWonValue,
          stats.contractorLostValue,
          stats.omicronLostValue,
          stats.omicronWonValue,
          stats.partPOReciveValue,
          stats.completePOReciveValue,
        ],
        backgroundColor: [
          "#FF5733", // Bidding
          "#33FF57", // Contractor Won
          "#3357FF", // Contractor Lost
          "#FF33A1", // Omicron Lost
          "#33FFF2", // Omicron Won
          "#FFAF33", // Part PO Received
          "#9933FF", // Complete PO Received
        ],
        hoverBackgroundColor: [
          "#FF5733",
          "#33FF57",
          "#3357FF",
          "#FF33A1",
          "#33FFF2",
          "#FFAF33",
          "#9933FF",
        ],
      },
    ],
  };

  const lineData = {
    labels: stats.monthWiseStats.map(
      (monthStat) => `${monthStat._id.month}/${monthStat._id.year}`
    ),
    datasets: [
      {
        label: "Month-wise Quote Value",
        data: stats.monthWiseStats.map(
          (monthStat) => monthStat.monthTotalQuoteValue
        ),
        fill: false,
        borderColor: "rgba(8, 101, 201, 0.932)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="reports-container">
      <h2 className="report-title">Project Reports of {userName}</h2>
      <div className="charts-wrapper">
        <div className="chart-container-1">
          <h3 className="chart-title">Total Projects: {stats.totalProjects}</h3>
        </div>
        <div className="chart-container-2">
          <h3 className="chart-title">
            Total Quote Value: {formatCurrency(stats.totalQuoteValue)}
          </h3>
        </div>
        <div className="chart-container-3">
          <h3 className="chart-title">
            Total Won Value: {formatCurrency(stats.totalWonValue)}
          </h3>
        </div>
        <div className="chart-container-4">
          <Doughnut
            data={doughnutData}
            options={{
              plugins: {
                datalabels: {
                  formatter: (value, context) => {
                    return value > 0
                      ? `${(
                          (value /
                            context.dataset.data.reduce(
                              (sum, val) => sum + val,
                              0
                            )) *
                          100
                        ).toFixed(2)}%`
                      : "";
                  },
                  color: "#fff",
                },
                legend: {
                  display: true,
                  position: "top",
                  align: "start",
                },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      const value = tooltipItem.raw;
                      const total = tooltipItem.chart.data.datasets[
                        tooltipItem.datasetIndex
                      ].data.reduce((sum, val) => sum + val, 0);
                      const percentage = total
                        ? ((value / total) * 100).toFixed(2) + "%"
                        : "0%";

                      return `${tooltipItem.label}: ${formatCurrency(
                        value
                      )} (${percentage})`;
                    },
                  },
                },
              },
            }}
          />
          <h3 className="chart-title">Project Report by Status</h3>
        </div>
        <div className="chart-container-5">
          <Bar data={barData} />
          <h3 className="chart-title">Projects by Status</h3>
        </div>
        <div className="chart-container-6">
          <Line data={lineData} />
          <h3 className="chart-title">Month-wise Quote Value</h3>
        </div>
      </div>
    </div>
  );
}

export default Reports;
