import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../App';
import Logo from '../../Assets/Images/logo.png';
import './resetpassword.css';

function ResetPassword() {
    const { dispatch } = useContext(UserContext);
    const navigate = useNavigate();
    const { token } = useParams();  // Get token from URL
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');

    const handleReset = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}reset-password/${token}`, { newPassword });
            setMessage(response.data.message);
            if (response.status === 200) {
                // Assuming the response contains the token and user data
                const { data } = response;
                localStorage.removeItem('jwt', data.token);
                localStorage.removeItem('user', JSON.stringify(data.user));
                dispatch({ type: "USER", payload: data.user });
                console.log(data.user);
                navigate('/login');
            }
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Server error');
            }
        }
    };

    return (
        <div className='reset-pass-wrap'>
            <div className="reset-left">
            </div>
            <div className="reset-right">
                <div className="form-box">
                    <div className="reset-logo">
                        <img src={Logo} alt="logo" />
                    </div>
                    <span>Enter Your New Password Here</span>
                    <div className="input-box">
                        <form onSubmit={handleReset}>
                            <div className='reset-input'>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    name='resetPass'
                                />
                                <label htmlFor='resetPass' className='input-title'>New Password</label>
                                <span
                                    className='show-hide'
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? "Hide" : "Show"}
                                </span>
                            </div>
                            <button type="submit" className='reset-btn'>Reset Password</button>
                        </form>
                    </div>
                    {message && <p>{message}</p>}
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
