import React, { useState } from "react";
import "./Followup.css";

function Followup({ taskId, onClose, onSubmit }) {
  const [followupText, setFollowupText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(taskId, followupText);
    setFollowupText("");
  };

  return (
    <div className="followup-overlay">
      <div className="followup-box">
        <h3>Add Follow-up</h3>
        <form onSubmit={handleSubmit}>
          <textarea
            value={followupText}
            onChange={(e) => setFollowupText(e.target.value)}
            placeholder="Enter follow-up details..."
            required
          ></textarea>
          <div className="followup-actions">
            <button type="submit" className="btn btn-submit">
              Submit
            </button>
            <button type="button" className="btn btn-cancel" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Followup;
