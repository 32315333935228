import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/personalDetails.css';

function PersonalDetails({ data, isEditMode, onCancel }) {
  const [parsedData, setParsedData] = useState({});

  // Parse the address and extract details like nationality
  const parseAddress = (address) => {
    if (!address) return {};

    // Split the address by commas and trim any spaces
    const parts = address.split(',').map(part => part.trim());

    // Get the country from the last element of the array
    const country = parts[parts.length - 1] || '';

    let nationality = '';

    // Set nationality based on the country
    if (country === 'India') {
      nationality = 'Indian';
    } else if (country === 'America' || country === 'USA') {
      nationality = 'American';
    } else {
      nationality = country; // Use the country name as the nationality if it's not specifically handled
    }

    // Return the parsed address and nationality
    return {
      street1: parts[0] || '',
      street2: parts[1] || '',
      city: parts[2] || '',
      zip: parts[3] || '',
      state: parts[4] || '',
      country: country,
      nationality: nationality,
    };
  };

  // Format date to "yyyy-MM-dd" for the <input type="date">
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const dd = String(d.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  // Effect to parse the data and set the state
  useEffect(() => {
    const parsedAddress = parseAddress(data?.Address);
    setParsedData({
      ...data,
      nationality: parsedAddress.nationality,
    });
  }, [data]);

  // Handle save function to update personal details
  const handleSave = async () => {
    const addressString = [
      parsedData.street1,
      parsedData.street2,
      parsedData.city,
      parsedData.zip,
      parsedData.state,
      parsedData.country
    ].join(', ');

    const updatedPersonalDetails = {
      FirstName: parsedData.FirstName,
      MiddleName: parsedData.MiddleName,
      LastName: parsedData.LastName,
      DOB: parsedData.DOB,
      BloodGroup: parsedData.BloodGroup,
      Nationality: parsedData.nationality,
      AdharNumber: parsedData.AdharNumber,
      Pan: parsedData.Pan,
      UAN: parsedData.UAN,
      PolicyNumber: parsedData.PolicyNumber,
      Address: addressString,
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}edit-user-profile/${data._id}`, updatedPersonalDetails);
      console.log('Personal details updated:', response.data);
      if (onCancel) {
        onCancel(); // Call onCancel or other callback after successful save
      }
    } catch (error) {
      console.error('Error updating personal details:', error);
    }
  };

  return (
    <div className="personal-details-wrapper">
      <div className="personal-details-container">
        <span className="personal-details-title">Personal Details</span>
        <hr className="divider" />

        {/* Name Section */}
        <div className="input-fields">
          <div className="input-group">
            <label htmlFor="first-name" className="input-label">First Name</label>
            <input
              type="text"
              id="first-name"
              readOnly={!isEditMode}
              className="input-field"
              name="firstName"
              value={parsedData.FirstName || ''}
              onChange={(e) => setParsedData({ ...parsedData, FirstName: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label htmlFor="middle-name" className="input-label">Middle Name</label>
            <input
              type="text"
              id="middle-name"
              readOnly={!isEditMode}
              className="input-field"
              name="middleName"
              value={parsedData.MiddleName || ''}
              onChange={(e) => setParsedData({ ...parsedData, MiddleName: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label htmlFor="last-name" className="input-label">Last Name</label>
            <input
              type="text"
              id="last-name"
              readOnly={!isEditMode}
              className="input-field"
              name="lastName"
              value={parsedData.LastName || ''}
              onChange={(e) => setParsedData({ ...parsedData, LastName: e.target.value })}
            />
          </div>
        </div>

        <hr className="divider" />

        {/* Date of Birth and Blood Group Section */}
        <div className="input-fields">
          <div className="input-group">
            <label htmlFor="date-of-birth" className="input-label">Date of Birth</label>
            <input
              type="date"
              id="date-of-birth"
              readOnly={!isEditMode}
              className="input-field"
              name="dateOfBirth"
              value={formatDate(parsedData.DOB || '')}
              onChange={(e) => setParsedData({ ...parsedData, DOB: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label htmlFor="blood-group" className="input-label">Blood Group</label>
            <input
              type="text"
              id="blood-group"
              readOnly={!isEditMode}
              className="input-field"
              name="bloodGroup"
              value={parsedData.BloodGroup || ''}
              onChange={(e) => setParsedData({ ...parsedData, BloodGroup: e.target.value })}
            />
          </div>
        </div>

        <hr className="divider" />

        {/* Nationality, Adhar Number, and Pan Number Section */}
        <div className="input-fields">
          <div className="input-group">
            <label htmlFor="nationality" className="input-label">Nationality</label>
            <input
              type="text"
              id="nationality"
              readOnly={!isEditMode}
              className="input-field"
              name="nationality"
              value={parsedData.nationality || ''}
              onChange={(e) => setParsedData({ ...parsedData, nationality: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label htmlFor="adhar-number" className="input-label">Adhar Number</label>
            <input
              type="text"
              id="adhar-number"
              readOnly={!isEditMode}
              className="input-field"
              name="adharNumber"
              value={parsedData.AdharNumber || ''}
              onChange={(e) => setParsedData({ ...parsedData, AdharNumber: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label htmlFor="pan-number" className="input-label">Pan Number</label>
            <input
              type="text"
              id="pan-number"
              readOnly={!isEditMode}
              className="input-field"
              name="panNumber"
              value={parsedData.Pan || ''}
              onChange={(e) => setParsedData({ ...parsedData, Pan: e.target.value })}
            />
          </div>
        </div>

        <hr className="divider" />

        {/* PF Number and Policy Number Section */}
        <div className="input-fields">
          <div className="input-group">
            <label htmlFor="pf-number" className="input-label">PF Number</label>
            <input
              type="text"
              id="pf-number"
              readOnly={!isEditMode}
              className="input-field"
              name="pfNumber"
              value={parsedData.UAN || ''}
              onChange={(e) => setParsedData({ ...parsedData, UAN: e.target.value })}
            />
          </div>
          <div className="input-group">
            <label htmlFor="policy-number" className="input-label">Policy Number</label>
            <input
              type="text"
              id="policy-number"
              readOnly={!isEditMode}
              className="input-field"
              name="policyNumber"
              value={parsedData.PolicyNumber || ''}
              onChange={(e) => setParsedData({ ...parsedData, PolicyNumber: e.target.value })}
            />
          </div>
        </div>

        {/* Save Button (conditional) */}
        {isEditMode && (
          <div className="save-btn-container">
            <button className="save-btn" onClick={handleSave}>Save</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PersonalDetails;
