// src/Reducer/userReducer.js

export const initialState = {
    user: null,
    isLoggedIn: false,
    // other initial state properties as needed
  };
  
  export const reducer = (state, action) => {
    switch (action.type) {
      case 'USER':
        return {
          ...state,
          user: action.payload,
          isLoggedIn: true,
        };
      case 'LOGOUT':
        return {
          ...state,
          user: null,
          isLoggedIn: false,
        };
      default:
        return state;
    }
  };
  