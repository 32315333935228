import React, { useState } from 'react';
import Logo from '../../Assets/Images/logo.png';
import './reqestpassword.css';
import {Link} from 'react-router-dom'

function RequestPassword() {
  const [Email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState('');

  const handlereset = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}request-reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ Email })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        console.error("Response Status:", response.status);
        console.error("Response Data:", data);
        setErrorMessage(data.error || "An error occurred. Please try again.");
        return;
      }
  
      if (data.error) {
        setErrorMessage(data.error);
      } else if (data.message) {
        setMessage(data.message);
      } else {
        setMessage("Password reset request sent successfully. Please check your email.");
      }
    } catch (error) {
      console.error("Fetch error: ", error);
      setErrorMessage("Network response was not ok. Please try again.");
    }
  };
  

  return (
    <div className='reqreset-wrap'>
      <div className="reqreset-left"></div>
      <div className="reqreset-right">
        <div className="reqreset-form">
          <div className="reqreset-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <span className='info-text'>Please enter your official email id Login?</span>
          <input
            type="text"
            placeholder="Email or Username"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handlereset}>Reset Password</button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="message">
            {message && <p>{message}</p>}
          </div>
          <div className="error-message">
            Already Have Password! <Link to='/login'>Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestPassword;
