import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import EmployeePermissionList from "./EmployeepermissionList";
import "./employeepermissionlist.css";
import './employees.css';

function EmployeesDB() {
  const [data, setData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeResponse = await fetch(`${process.env.REACT_APP_API_URL}employee-dashboard`, {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("jwt")
          }
        });
        const employeeResult = await employeeResponse.json();
        if (employeeResult && Array.isArray(employeeResult.users)) {
          setData(employeeResult.users);
        } else {
          console.error('Expected array but received:', employeeResult);
        }

        const projectUsersResponse = await fetch(`${process.env.REACT_APP_API_URL}projectusers`, {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("jwt")
          }
        });
        const projectUsersResult = await projectUsersResponse.json();
        if (projectUsersResult && Array.isArray(projectUsersResult.allUsers)) {
          setAllUsers(projectUsersResult.allUsers);
        } else {
          setAllUsers(Object.values(projectUsersResult.allUsers));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleProfileClick = (user) => {
    setSelectedUser(user);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedUser(null);
  };

  const handleSavePermissions = (selectedEmployees) => {
    console.log('Selected employees:', selectedEmployees);
    // Update local state or refetch data if necessary
    fetchData(); // Assuming fetchData function is available to refetch employee data
    handleClosePopup();
  };

  const fetchData = async () => {
    try {
      const employeeResponse = await fetch(`${process.env.REACT_APP_API_URL}employee-dashboard`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      });
      const employeeResult = await employeeResponse.json();
      if (employeeResult && Array.isArray(employeeResult.users)) {
        setData(employeeResult.users);
      } else {
        console.error('Expected array but received:', employeeResult);
      }
    } catch (error) {
      console.error('Error refetching employee data:', error);
    }
  };

  return (
    <div className="Employee-DB-wrap">
      <div className="data-filter-tab">
        <div className="page-title">
          <h1>Employee</h1>
        </div>
        
      </div>
      <div className="employees-data">
        <div className="employee-list">
          <div className="employee-grid">
            {Array.isArray(data) && data.map(item => (
              <div className="employee-grid-item" key={item._id} onClick={() => handleProfileClick(item)}>
                <div className="employee-profile">
                  <Avatar alt={item.FirstName + " " + item.LastName} src={item.ProfileImageUrl} sx={{ width: 85, height: 85 }} />
                  <span className="employee-designation">{item.Designation}</span>
                  <span className="employee-name">{item.FirstName} {item.LastName}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showPopup && selectedUser && (
        <div className="popup-overlay">
          <div className="popup-content">
            <EmployeePermissionList
              selectedUser={selectedUser}
              allUsers={allUsers}
              onClose={handleClosePopup}
              onSave={handleSavePermissions}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default EmployeesDB;
