import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.css";
import Followup from "./Followup";
import Reports from "../../component/Reports/Reports";
import Avatar from "@mui/material/Avatar";
import { Link, useLocation } from "react-router-dom";

function DashBoard() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [showFollowup, setShowFollowup] = useState(false);
  const [followupTaskId, setFollowupTaskId] = useState(null);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [pendingFollowup, setPendingFollowup] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedUserId = searchParams.get("userId");
  const userObject = JSON.parse(localStorage.getItem("user") || "{}");
  const loggedInUserId = userObject._id || "";

  const priorityColors = {
    Low: "#8df78d",
    Medium: "#fac37d",
    High: "#fc8781",
    Critical: "#e6e6ff",
  };

  // Fetch notifications
  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}notifications`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setNotifications(data.notifications);
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to fetch notifications");
    }
  };

  // Fetch users
  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}projectusers`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      const loggedInUser = data.allUsers.find((user) => user._id === loggedInUserId);

      if (loggedInUser && loggedInUser.PermissiontoSee) {
        const usersToShow = data.allUsers.filter((user) =>
          loggedInUser.PermissiontoSee.includes(user._id)
        );
        setFilteredUsers(usersToShow);
      }
      setUsers(data.allUsers);
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to fetch users");
    }
  }, [loggedInUserId]);

  // Fetch tasks
  const fetchTasks = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}tasks/${loggedInUserId}`);
      if (!response.ok) throw new Error("Failed to fetch tasks");
      const data = await response.json();
      setTaskList(data.assignedTasks || []);
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to fetch tasks");
    }
  }, [loggedInUserId]);

  // Fetch all data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchNotifications();
      await fetchUsers();
      await fetchTasks();
      setLoading(false);
    };
    fetchData();
  }, [fetchUsers, fetchTasks]);

  const handleStatusChange = async (taskId, newStatus) => {
    if (newStatus === "Completed") {
      setFollowupTaskId(taskId);
      setShowFollowup(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}tasks/${taskId}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: newStatus }),
      });
      if (!response.ok) throw new Error("Failed to update task status");
      setTaskList((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, status: newStatus } : task
        )
      );
      toast.success("Task status updated successfully");
    } catch (error) {
      console.error("Error updating task status:", error);
      toast.error("Failed to update task status");
    }
  };

  const handleFollowupSubmit = (taskId, followupText) => {
    setPendingFollowup({ taskId, followupText });
    setShowFollowup(false);
    setConfirmSubmit(true);
  };

  const confirmFollowupSubmit = async () => {
    if (!pendingFollowup) return;

    const { taskId, followupText } = pendingFollowup;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}tasks/${taskId}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: "Completed", followup: followupText }),
      });
      if (!response.ok) throw new Error("Failed to submit follow-up");
      setTaskList((prevTasks) =>
        prevTasks.filter((task) => task._id !== taskId)
      );
      setConfirmSubmit(false);
      setPendingFollowup(null);
      toast.success("Follow-up submitted successfully");
    } catch (error) {
      console.error("Error submitting follow-up:", error);
      toast.error("Failed to submit follow-up");
    }
  };

  const cancelFollowupSubmit = () => {
    setConfirmSubmit(false);
    setPendingFollowup(null);
  };

  const shouldShowReports =
    filteredUsers.length === 1 && filteredUsers[0]._id === loggedInUserId;

  return (
    <div className="dashboard-wrap">
      {showFollowup && (
        <Followup
          taskId={followupTaskId}
          onClose={() => setShowFollowup(false)}
          onSubmit={handleFollowupSubmit}
        />
      )}

      {confirmSubmit && (
        <div className="confirmation-overlay">
          <div className="confirmation-dialog">
            <p>Are you sure you want to submit this follow-up?</p>
            <div className="confirmation-buttons">
              <button onClick={confirmFollowupSubmit}>Yes</button>
              <button onClick={cancelFollowupSubmit}>No</button>
            </div>
          </div>
        </div>
      )}

      <div className="dashboard-grid">
        <div className="db-grid-item tasks-section">
          <h3>My Tasks</h3>
          {taskList.length > 0 ? (
            <div className="tasks-wrapper">
              {taskList.map((taskItem) => (
                <div
                  key={taskItem._id}
                  className="task-card"
                  style={{
                    borderLeft: `5px solid ${priorityColors[taskItem.priority]}`,
                  }}
                >
                  <div className="task-header">
                    <h4>{taskItem.title}</h4>
                    <span className="task-priority">{taskItem.priority}</span>
                  </div>
                  <p className="task-description">{taskItem.description}</p>
                  <div className="task-actions">
                    <label htmlFor={`status-${taskItem._id}`}>Status:</label>
                    <select
                      id={`status-${taskItem._id}`}
                      value={taskItem.status}
                      onChange={(e) => handleStatusChange(taskItem._id, e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-tasks">
              <p>No tasks assigned to you.</p>
            </div>
          )}
        </div>
        <div className="db-grid-item">Quick Launch</div>
        <div className="db-grid-item notification-wrap">
          <div className="notifications">
            {loading ? (
              <div>Loading notifications...</div>
            ) : notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <div
                  key={index}
                  className={`notification ${notification.type
                    .toLowerCase()
                    .replace(" ", "-")}`}
                >
                  {notification.message}
                </div>
              ))
            ) : (
              <div>No notifications for today</div>
            )}
          </div>
        </div>
        <div className="db-grid-item">Employees on Leave Today</div>
      </div>

      {selectedUserId ? (
        <Reports
          userId={selectedUserId}
          userName={
            users.find((user) => user._id === selectedUserId)?.FirstName +
            " " +
            users.find((user) => user._id === selectedUserId)?.LastName
          }
        />
      ) : shouldShowReports ? (
        <Reports
          userId={loggedInUserId}
          userName={userObject.FirstName + " " + userObject.LastName}
        />
      ) : (
        <div className="user-profiles">
          {filteredUsers.map((user) => (
            <Link
              key={user._id}
              to={`?userId=${user._id}`}
              className="profile-card"
            >
              <Avatar
                src={`path_to_image/${user._id}`} // Update with actual image path
                alt={`${user.FirstName} ${user.LastName}`}
              />
              <div className="profile-info">
                <h3>
                  {user.FirstName} {user.LastName}
                </h3>
                <p>{user.Designation}</p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default DashBoard;
