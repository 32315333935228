// src/component/ProfileDropdown/ProfileDropdown.js
import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../App'; // Adjust the path as necessary
import './profileDropdown.css';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate if using react-router-dom

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const dropdownRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = async () => {
    try {
      // Optional: Call backend logout endpoint here

      // Clear the user and JWT from localStorage
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');

      // Dispatch a logout action to update the state
      dispatch({ type: 'LOGOUT' });

      // Redirect to the login page or another appropriate page
      navigate('/login');
    } catch (error) {
      console.log("Logout Error:", error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const userName = state.user ? state.user.FirstName +" "+ state.user.LastName : 'Guest'; // Adjust this based on your user object structure

  const userId = state.user._id
  

  return (
    <div className="profile-dropdown" ref={dropdownRef}>
      <div className="profile-header" onClick={toggleDropdown}>
        <img src='/' alt="" className="profile-picture" />
        <h2 className="profile-name">{userName}</h2>
        <div className="dropdown-triangle"></div>
      </div>
      {isOpen && (
        <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
          <Link to={`/employee-profile/${userId}`}><li>Profile</li></Link>
          <li onClick={handleLogout}>Logout</li>
        </ul>
      )}
    </div>
  );
};

export default ProfileDropdown;
