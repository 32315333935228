import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import './styles/employeeProfile.css';
import { toast } from 'react-toastify';
import Preview from '../../Assets/Images/profile-prev.png';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import Myteam from './Myteam';
import axios from 'axios';

function EmployeeProfile({ userId }) {
  const [currentView, setCurrentView] = useState('personalDetails');
  const [profileData, setProfileData] = useState(null); // Combine profile and contact details
  const [isEditMode, setIsEditMode] = useState(false);


  const user = JSON.parse(localStorage.getItem("user"));
  userId = user ? user._id : null;

  useEffect(() => {
    const fetchProfileData = async () => {
      if (userId) { // Ensure userId is valid before fetching
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}user-profile/${userId}`);
          setProfileData(response.data);
        } catch (error) {
          console.error("Error fetching profile data:", error);
          toast.error('Failed to fetch profile data!');
        }
      }
    };

    fetchProfileData();
  }, [userId]);


  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}user-profile/${userId}`, profileData); // Save logic
      toast.success('Saved successfully!');
    } catch (error) {
      console.error("Error saving profile data:", error);
      toast.error('Failed to save profile data!');
    }
    setIsEditMode(false);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const renderContent = () => {
    if (!profileData) return <p>Loading...</p>;

    switch (currentView) {
      case 'personalDetails':
        return <PersonalDetails data={profileData} isEditMode={isEditMode} />;
      case 'contactDetails':
        return <ContactDetails contactDetails={profileData} isEditMode={isEditMode} />;
      case 'myteam':
        return <Myteam data={profileData?.PermissiontoSee} isEditMode={isEditMode} />;
      default:
        return <PersonalDetails data={profileData} isEditMode={isEditMode} />;
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className='profile-main-wrap'>
      {/* Profile Left area */}
      <div className="profile-box">

        <div className="profile-menu">
          <div className="profilepic">
            <Avatar className='profile-pic' alt='Profile Picture' src={profileData?.ProfileImageUrl || Preview} sx={{ width: 100, height: 100 }} />
            <div className="designation">
              <p>Date of Join: <span>{formatDate(profileData?.DateofJoin)}</span></p>
              <p>Department: <span>{profileData?.Department}</span></p>
              <p>Designation: <span>{profileData?.Designation}</span></p>
              <p>Location: <span>{profileData?.Location}</span></p>
            </div>
          </div>
          <hr />
          <div className="profile-links">
            <ul>
              <li className={currentView === 'personalDetails' ? 'active' : ''} data={profileData} onClick={() => setCurrentView('personalDetails')}>Personal Details</li>
              <li className={currentView === 'contactDetails' ? 'active' : ''} onClick={() => setCurrentView('contactDetails')}>Contact Details</li>
              {/* <li className={currentView === 'myteam' ? 'active' : ''} onClick={() => setCurrentView('myteam')}>Team</li> */}
            </ul>
          </div>
        </div>

        {/* Profile Right area */}
        <div className="profile-details">
          {renderContent()}
          <div className="actions save-data">
            {isEditMode ? (
              <button onClick={handleSave}>Save</button>
            ) : (
              <button onClick={handleEdit}>Edit</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeProfile;
