import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TaskManager.css';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

const TaskManager = () => {
    const [employees, setEmployees] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [priority, setPriority] = useState('Medium');
    const [taskList, setTaskList] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [bulkAction, setBulkAction] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [searchEmployee, setSearchEmployee] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}projectusers`);
                setEmployees(response.data.allUsers);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}tasks`);
                setTaskList(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchEmployees();
        fetchTasks();
    }, []);

    useEffect(() => {
        if (searchEmployee) {
            setFilteredEmployees(
                employees.filter((employee) =>
                    `${employee.FirstName} ${employee.LastName}`
                        .toLowerCase()
                        .includes(searchEmployee.toLowerCase())
                )
            );
        } else {
            setFilteredEmployees([]);
        }
    }, [searchEmployee, employees]);

    const handleAssignTask = async () => {
        if (title && description && selectedEmployee) {
            const newTask = {
                title,
                description,
                assignedTo: selectedEmployee,
                priority,
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}tasks`, newTask);
                setTaskList((prevTasks) => [...prevTasks, response.data]);
                setTitle('');
                setDescription('');
                setSelectedEmployee('');
                setPriority('Medium');
                setShowModal(false);
            } catch (error) {
                alert('Error assigning task: ' + error.message);
            }
        } else {
            alert('Please fill in all fields.');
        }
    };

    const handleStatusChange = async (taskId, newStatus) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}tasks/${taskId}/status`, { status: newStatus });
            setTaskList((prevTasks) =>
                prevTasks.map((task) =>
                    task._id === taskId ? { ...task, status: newStatus } : task
                )
            );
        } catch (error) {
            console.error('Error updating task status:', error);
            alert('Error updating task status: ' + error.message);
        }
    };

    const handleDeleteTask = async (taskId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this task?");
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}tasks/${taskId}`);
                setTaskList((prevTasks) => prevTasks.filter(task => task._id !== taskId));
                toast.success('Task deleted successfully!');
            } catch (error) {
                console.error('Error deleting task:', error);
                alert('Error deleting task: ' + error.message);
            }
        }
    };

    const handleBulkDelete = async () => {
        if (selectedTasks.length === 0) {
            toast.warn('No tasks selected for deletion.');
            return;
        }

        const confirmDelete = window.confirm("Are you sure you want to delete the selected tasks?");
        if (confirmDelete) {
            try {
                const deletePromises = selectedTasks.map(taskId =>
                    axios.delete(`${process.env.REACT_APP_API_URL}tasks/${taskId}`)
                );
                await Promise.all(deletePromises);
                setTaskList(prevTasks => prevTasks.filter(task => !selectedTasks.includes(task._id)));
                toast.success('Selected tasks deleted successfully!');
                setSelectedTasks([]);
            } catch (error) {
                console.error('Error deleting tasks:', error);
                alert('Error deleting tasks: ' + error.message);
            }
        }
    };

    const handleBulkStatusUpdate = async () => {
        if (selectedTasks.length === 0) {
            toast.warn('No tasks selected for status update.');
            return;
        }

        try {
            const updatePromises = selectedTasks.map(taskId => {
                return axios.put(`${process.env.REACT_APP_API_URL}tasks/${taskId}/status`, { status: bulkAction });
            });
            await Promise.all(updatePromises);
            setTaskList(prevTasks =>
                prevTasks.map(task =>
                    selectedTasks.includes(task._id) ? { ...task, status: bulkAction } : task
                )
            );
            setSelectedTasks([]);
            setBulkAction('');
            toast.success(`Selected tasks marked as ${bulkAction}!`);
        } catch (error) {
            console.error('Error updating tasks:', error);
            alert('Error updating tasks: ' + error.message);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedTasks([]);
        } else {
            setSelectedTasks(taskList.map((task) => task._id));
        }
        setSelectAll(!selectAll);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return '#ffcccb';
            case 'In Progress':
                return '#ffe5b4';
            case 'Completed':
                return '#d4edda';
            default:
                return 'white';
        }
    };

    return (
        <div className="task-manager">
            <h2>Task Manager</h2>
            <button onClick={() => setShowModal(true)} className="assign-task-button">Assign Task</button>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <h3>Assign Task</h3>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Task Title"
                        />
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Task Description"
                        />
                        <input
                            type="text"
                            value={searchEmployee}
                            onChange={(e) => setSearchEmployee(e.target.value)}
                            placeholder="Search Employee"
                        />
                        {searchEmployee && (
                            <div className="suggestions">
                                {filteredEmployees.map((employee) => (
                                    <div
                                        key={employee._id}
                                        onClick={() => {
                                            setSelectedEmployee(employee._id);
                                            setSearchEmployee(`${employee.FirstName} ${employee.LastName}`);
                                        }}
                                        className="suggestion-item"
                                    >
                                        {employee.FirstName} {employee.LastName} ({employee.Designation})
                                    </div>
                                ))}
                            </div>
                        )}
                        <select value={priority} onChange={(e) => setPriority(e.target.value)}>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                        </select>
                        <button onClick={handleAssignTask}>Assign Task</button>
                    </div>
                </div>
            )}

            
            <div className='bulk-action-btn'>
                <select 
                    value={bulkAction} 
                    onChange={(e) => setBulkAction(e.target.value)} 
                >
                    <option value="">Select Bulk Action</option>
                    <option value="Pending">Mark Selected as Pending</option>
                    <option value="In Progress">Mark Selected as In Progress</option>
                    <option value="Completed">Mark Selected as Completed</option>
                    <option value="Delete">Delete Selected</option>
                </select>
                <button onClick={() => bulkAction === 'Delete' ? handleBulkDelete() : handleBulkStatusUpdate()}>Apply</button>
            </div>
            <table className="task-table">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Task Title</th>
                        <th>Assigned To</th>
                        <th>Description</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Follow-up</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {taskList.map((taskItem) => (
                        <tr key={taskItem._id} style={{ backgroundColor: getStatusColor(taskItem.status) }}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedTasks.includes(taskItem._id)}
                                    onChange={() =>
                                        setSelectedTasks((prevSelected) =>
                                            prevSelected.includes(taskItem._id)
                                                ? prevSelected.filter((id) => id !== taskItem._id)
                                                : [...prevSelected, taskItem._id]
                                        )
                                    }
                                />
                            </td>
                            <td>{taskItem.title}</td>
                            <td>{taskItem.assignedTo ? taskItem.assignedTo.FirstName + ' ' + taskItem.assignedTo.LastName : 'Unassigned'}</td>
                            <td>{taskItem.description}</td>
                            <td>{taskItem.priority}</td>
                            <td>
                                <select
                                    value={taskItem.status}
                                    onChange={(e) => handleStatusChange(taskItem._id, e.target.value)}
                                >
                                    <option value="Pending">Pending</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </td>
                            <td>
    <Tooltip title={taskItem.followup || "No follow-up details"}>
        <span>{taskItem.followup || "N/A"}</span>
    </Tooltip>
</td>
                            <td>
                                <button onClick={() => handleDeleteTask(taskItem._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TaskManager;
