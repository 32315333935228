import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
  Snackbar,
  Alert,
  Tooltip // Import Tooltip from MUI
} from '@mui/material';

const ArchiveProject = () => {
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allowedColumns, setAllowedColumns] = useState([]);
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user._id : null
  

  const fetchArchivedProjects = useCallback(async () => {
    if (!userId) {
      console.error("User ID is undefined");
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}show-archived-projects`, {
        params: { userId },
      });
      setArchivedProjects(response.data.projects);
    } catch (err) {
      console.error('Error fetching archived projects:', err.response ? err.response.data : err.message);
      setError(err.response?.data?.error || 'An error occurred while fetching archived projects.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}projectusers`);
      const currentUser = response.data.allUsers.find((u) => u._id === userId);
      setAllowedColumns(currentUser.AllowedColumns || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [userId]);

  useEffect(() => {
    fetchArchivedProjects();
    fetchUserData();
  }, [fetchArchivedProjects, fetchUserData]);

  const handleUnarchive = async (projectId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}unarchive-project/${projectId}`, { userId });
      setArchivedProjects((prev) => prev.filter((project) => project._id !== projectId));
      setSuccessMessage('Project unarchived successfully!');
    } catch (err) {
      console.error('Error unarchiving project:', err);
      setError(err.response?.data?.error || 'An error occurred while unarchiving the project.');
    }
  };

  const handleBulkUnarchive = async () => {
    try {
      await Promise.all(bulkSelectedIds.map((id) => 
        axios.post(`${process.env.REACT_APP_API_URL}unarchive-project/${id}`, { userId })
      ));
      setArchivedProjects((prev) => prev.filter((project) => !bulkSelectedIds.includes(project._id)));
      setBulkSelectedIds([]);
      setSuccessMessage('Selected projects unarchived successfully!');
    } catch (err) {
      console.error('Error unarchiving selected projects:', err);
      setError(err.response?.data?.error || 'An error occurred while unarchiving the selected projects.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBulkUnarchive}
        disabled={bulkSelectedIds.length === 0}
      >
        Unarchive Selected
      </Button>
      <Button><Link to={`/project-table/${userId}`}>Show Active</Link></Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="table-cell">
                <Checkbox
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setBulkSelectedIds(checked ? archivedProjects.map((row) => row._id) : []);
                  }}
                />
              </TableCell>
              {allowedColumns.map((column) => (
                <TableCell className="table-cell" key={column} style={{ fontWeight: "600" }}>
                  {column}
                </TableCell>
              ))}
              <TableCell className="table-cell" style={{ fontWeight: "600" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {archivedProjects.map((row) => (
              <TableRow key={row._id}>
                <TableCell>
                  <Checkbox
                    checked={bulkSelectedIds.includes(row._id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setBulkSelectedIds((prev) =>
                        checked ? [...prev, row._id] : prev.filter((id) => id !== row._id)
                      );
                    }}
                  />
                </TableCell>
                {allowedColumns.map((column) => (
                  <TableCell key={column}>
                    {column === 'MakeList' ? (
                      <Tooltip
                        title={row.Specifications.join(', ') || 'No specifications available'}
                        placement="top"
                        PopperProps={{
                          sx: {
                            '.MuiTooltip-tooltip': {
                              maxWidth: '300px', // Adjust as needed
                              fontSize: '1.2rem', // Adjust as needed
                              padding: '10px 15px', // Adjust as needed
                            },
                          },
                        }}
                      >
                        <span style={{ cursor: 'pointer', display: 'inline-block' }}>
                          {row.MakeList}
                        </span>
                      </Tooltip>
                    ) : (
                      <TextField
                        value={row[column]}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  <Button onClick={() => handleUnarchive(row._id)}>Unarchive</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ArchiveProject;
