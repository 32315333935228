import React, { useState, useEffect } from 'react';
import Avatar from "@mui/material/Avatar";
import { Link } from 'react-router-dom';
import ProfileCard from './profileCard';
import './organization.css';

function Organization({ userRole }) {
    const [data, setData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);
                const employeeResponse = await fetch(`${process.env.REACT_APP_API_URL}employee-dashboard`, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("jwt")
                    }
                });
                const employeeResult = await employeeResponse.json();
                if (employeeResult && Array.isArray(employeeResult.users)) {
                    setData(employeeResult.users);
                } else {
                    console.error('Expected array but received:', employeeResult);
                }

                const projectUsersResponse = await fetch(`${process.env.REACT_APP_API_URL}projectusers`, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("jwt")
                    }
                });
                const projectUsersResult = await projectUsersResponse.json();
                if (projectUsersResult && Array.isArray(projectUsersResult.allUsers)) {
                    setAllUsers(projectUsersResult.allUsers);
                } else {
                    setAllUsers(Object.values(projectUsersResult.allUsers));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleProfileClick = (user) => {
        setSelectedUser(user);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedUser(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }


    return (
        <div >
            {/* {userRole === "administrator" && ( */}
                <div className="filters">
                    <Link to='/addemployee'>
                        <div className="add-employee">+ Add Employee</div>
                    </Link>
                </div>
            {/* )} */}
            <div className="organization-wrap">
            <div className={`organization-section ${showPopup ? 'expanded' : ''}`}>
                <div className="organization-grid">
                    {data.length === 0 ? (
                        <div>No employees found.</div>
                    ) : (
                        data.map(item => (
                            <div className="employee-grid-item" key={item._id} onClick={() => handleProfileClick(item)}>
                                <div className="employee-profile">
                                    <Avatar alt={item.FirstName + " " + item.LastName} src={item.ProfileImageUrl} sx={{ width: 85, height: 85 }} />
                                    <span className="employee-designation">{item.Designation}</span>
                                    <span className="employee-name">{item.FirstName} {item.LastName}</span>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            {showPopup && (
                <div className="profile-card-container show">
                    <ProfileCard
                        onClose={handleClosePopup}
                        selectedUser={selectedUser}
                        allUsers={allUsers}
                    />
                </div>
            )}
             </div>
        </div>
    );
}

export default Organization;
