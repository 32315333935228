import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './attendance.css';
import axios from 'axios';

const FetchAttendance = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState({});
  
  // Function to fetch attendance data from your backend or API
  const fetchAttendanceData = async () => {
    try {
      const params = {
        empCode: '0001',  // Replace with actual employee code or other query params
        fromDate: '01/01/2024_00:00',
        toDate: '31/12/2024_23:59'
      };

      console.log('Fetching attendance data with params:', params); // Log request params

      const response = await axios.get(`${process.env.REACT_APP_API_URL}fetch-attendance`, { params });

      console.log('Received attendance data:', response.data); // Log response data

      setAttendanceData(response.data); // Store the fetched data in state
    } catch (error) {
      console.error('Error fetching attendance data:', error); // Log error
    }
  };

  useEffect(() => {
    fetchAttendanceData(); // Fetch attendance data on component mount
  }, []);

  return (
    <div className="p-6 bg-card rounded-md box-glow">
      <h2 className="text-glow mb-4">Employee Attendance</h2>
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Calendar */}
        <div className="flex flex-col items-center bg-card rounded-md p-4 box-glow glow-hover">
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            className="border-none rounded-md"
          />
          <div className="mt-4 flex gap-4 text-white text-sm">
            <span className="legend-item bg-red-500"></span> Absent
            <span className="legend-item bg-yellow-500"></span> Half-Day
            <span className="legend-item bg-green-500"></span> Paid Leave
            <span className="legend-item bg-blue-500"></span> Public Holiday
          </div>
        </div>

        {/* Attendance Table */}
        <div className="overflow-auto w-full lg:w-1/2 bg-card p-4 rounded-md box-glow glow-hover">
          <table className="min-w-full shadow-md rounded-md text-white">
            <thead>
              <tr className="bg-gray-700 text-blue-300 uppercase text-sm">
                <th className="py-3 px-6 border-b border-gray-600">Date</th>
                <th className="py-3 px-6 border-b border-gray-600">Punch In</th>
                <th className="py-3 px-6 border-b border-gray-600">Punch Out</th>
                <th className="py-3 px-6 border-b border-gray-600">Total Hours</th>
                <th className="py-3 px-6 border-b border-gray-600">Overtime</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData &&
                Object.keys(attendanceData).map((date) => {
                  const { punchIn, punchOut, totalHours, overtime, status } = attendanceData[date];
                  return (
                    <tr key={date} className="hover:bg-gray-700 border-b border-gray-600">
                      <td className="py-3 px-6 text-center">{date}</td>
                      <td className="py-3 px-6 text-center">{punchIn || '-'}</td>
                      <td className="py-3 px-6 text-center">{punchOut || '-'}</td>
                      <td className="py-3 px-6 text-center">{totalHours || '0'} hrs</td>
                      <td className="py-3 px-6 text-center">{overtime || '0'} hrs</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FetchAttendance;
