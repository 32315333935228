import React,{useState, useEffect} from 'react'
import Avatar from "@mui/material/Avatar";

function Myteam() {

    const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/employee-dashboard`, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwt")
      }
    })
      .then(res => res.json())
      .then(result => {
        if (result && Array.isArray(result.users)) {
          setData(result.users);
          console.log(result.users)
        } else {
          console.error('Expected array but received:', result);
        }
      })
      .catch(error => {
        console.error('Error fetching employee data:', error);
      });
  }, []);


  return (
    <div>
      <div className="employee-grid" >
            {
              Array.isArray(data) && data.map(item => (

                <div className="employee-grid-item" key={item._id}>
                  <div className="employee-profile">
                    <Avatar alt={item.FirstName + " " + item.LastName} src={item.ProfileImageUrl} sx={{ width: 85, height: 85 }} />
                    <span className="employee-designation">{item.Designation}</span>
                    <span className="employee-name">{item.FirstName} {item.LastName}</span>
                  </div>
                  <div className="profile-settings">
                    <i className="fa-solid fa-ellipsis-vertical employee-profile-settings"></i>
                  </div>
                </div>

              ))
            }
          </div>
    </div>
  )
}

export default Myteam
